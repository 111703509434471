<template>
  <main>
    <div v-if="userType == 'retailer'">
      <div v-if="!loading">
        <v-main app>
          <div
            :class="{
              'page-wrapper-sh-retailer-setting':
                $route.path.includes('retailer/setting'),
            }"
            class="page-wrapper-sh-retailer"
          >
            <router-view />
          </div>
        </v-main>
      </div>
      <div class="spinner-container" v-else>
        <PSpinner color="teal" accessibilityLabel="Spinner Example" />
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="no-access">You don't have access to this panel</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AuthService from "@/services/AuthenticationService";
import createApp from "@shopify/app-bridge";
// import { getSessionToken } from "@shopify/app-bridge-utils";
import { isShopifyEmbedded,getSessionToken} from '@shopify/app-bridge/utilities';
import ValidateService from "@/services/ValidateService";
import SettingService from "@/services/SettingService";
import { TitleBar } from "@shopify/app-bridge/actions";
import RegisterationSetup from "@/services/RegisterationSetup";
import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
export default {
  data() {
    return {
      userType: "retailer",
      loading: null,
      shopifyURL: "",
      showSyncingProgress: false,
      currency_iso: null,
      shop_id: null,
      platform_name: null,
      timestamp: null,
      state: null,
      hmac: null,
      showAssignShopError: null,
      userRole: null,
      shopInstalled: false,
    };
  },
  async created() {
    this.loading = true;

    if (isShopifyEmbedded()) {
      
      
      try {
        const AppBridgeIsSet = await this.initiateAppBridge();

        if (AppBridgeIsSet) {
          const response = await AuthService.getRetailerInitialState(
            getVariable("shopifyToken")
          );

          this.userRole = getVariable("role");

          if (response?.status === 200) {
            if (!response.data.userConnected) {
              this.$router.push(
                "/sh-retailer/welcome-page?host=" + getVariable("host")
              );
              this.loading = false;
              return;
            } else if (
              this.currentRouteName === "ShopifyRetailerFindProducts"
            ) {
              this.$router.push("/sh-retailer/list-of-products");
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerImportList") {
              this.$router.push("/sh-retailer/import-list");
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerCheckout") {
              this.$router.push("/sh-retailer/checkout");
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerEditProduct") {
              this.$router.push("/sh-retailer/edit-product/:id");
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerMyProducts") {
              this.$router.push("/sh-retailer/my-products");
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerSetting") {
              this.$router.push("/sh-retailer/setting");
              this.loading = false;
              return;
            } else if (
              this.currentRouteName === "ShopifyRetailer" ||
              this.currentRouteName === "ShopifyRetailerOnboarding"
            ) {
              this.$router.push(
                "/sh-retailer/onboarding?host=" + getVariable("host")
              );
              this.loading = false;
              return;
            } else if (this.currentRouteName === "ShopifyRetailerOauth") {
              this.$router.push("/sh-retailer/oauth");
              this.loading = false;
              return;
            }
          } else {
            this.$router.push("/sh-retailer/welcome-page");
            this.loading = false;
            return;
          }
          if (this.userRole !== this.userType) {
            this.loading = false;
            return;
          }
        } else {
          this.loading = false;
        }
      } catch (e) {
        console.log("sh-retailer created error:", e);
      }
    } else {
    
      const params = window.location.href.split("?");
      let apiKey = process.env.VUE_APP_SHOPIFY_REDIRECT_RETAILER;

      if (window) {
         
        if (apiKey.endsWith("/")) {
          apiKey = apiKey.slice(0, -1);
        }
        //MO
        window.location.replace(apiKey + params[1]);
        //this.$router.push("/sh-retailer/list-of-products");
      }
      this.loading = false;
    }
  },
  mounted() {
    if (this.$route.query?.currency_iso) {
      this.currency_iso = this.$route.query.currency_iso;
    } else if (getVariable("currency_iso")) {
      this.currency_iso = getVariable("currency_iso");
    } else {
      this.currency_iso = null;
    }

    if (this.$route.query.shop_id) {
      this.shop_id = this.$route.query.shop_id;
    } else if (getVariable("shop_id")) {
      this.shop_id = getVariable("shop_id");
    } else {
      this.shop_id = null;
    }

    if (this.$route.query.platform_name) {
      this.platform_name = this.$route.query.platform_name;
    } else if (getVariable("platform_name")) {
      this.platform_name = getVariable("platform_name");
    } else {
      this.platform_name = null;
    }

    if (this.$route.query.timestamp) {
      this.timestamp = this.$route.query.timestamp;
    } else if (getVariable("timestamp")) {
      this.timestamp = getVariable("timestamp");
    } else {
      this.timestamp = null;
    }

    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    } else if (getVariable("state")) {
      this.state = getVariable("state");
    } else {
      this.state = null;
    }

    if (this.$route.query.hmac) {
      this.hmac = this.$route.query.hmac;
    } else if (getVariable("hmac")) {
      this.hmac = getVariable("hmac");
    } else {
      this.hmac = null;
    }
    if (this.shop_id && this.platform_name) {
      setVariable("currency_iso", this.currency_iso);
      setVariable("shop_id", this.shop_id);
      setVariable("platform_name", this.platform_name);
      setVariable("timestamp", this.timestamp);
      setVariable("state", this.state);
      setVariable("hmac", this.hmac);

      this.retailerValidate();
    } else {
      if (getVariable("user")) {
        this.getAccountInfo();
      }
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async initiateAppBridge() {
      try {
        const apiKey = process.env.VUE_APP_SHOPIFY_RETAILER_API_KEY;
        const url_string = window.location.href;
        const url = new URL(url_string);
        const host = url.searchParams.get("host");
       if(isShopifyEmbedded()) setVariable("host", host);
        

        const app = createApp({
          apiKey: apiKey,
          host: host,
        });

        const titleBarOptions = {
          title: "Vgang retailer",
        };
        TitleBar.create(app, titleBarOptions);

        const response = await getSessionToken(app);

        setVariable("shopifyToken", response);

        this.loading = false;
        return true;
      } catch (error) {
        console.log("retailer registration error:", error);
      }
    },
    getAccountInfo() {
      SettingService.getAccountInfo()
        .then((response) => {
          this.shopifyURL = response.data.shopUrl;

          if (response.data.shopUrl && response.data.shopInstalled) {
            this.shopInstalled = true;
          } else {
            this.shopInstalled = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retailerValidate() {
      const currencyIso = getVariable("currency_iso");
      const shopId = getVariable("shop_id");
      const platformName = getVariable("platform_name");
      const timestamp = getVariable("timestamp");
      const state = getVariable("state");
      const hmac = getVariable("hmac");
      const params = {
        currencyIso: currencyIso ? currencyIso : "",
        storeUrl: shopId ? shopId : "",
        platformName: platformName ? platformName : "",
        timestamp: timestamp ? timestamp : "",
        state: state ? state : "",
        hmac: hmac ? hmac : "",
      };
      ValidateService.retailerValidate(params)
        .then((response) => {
          this.showAssignShopError = false;
          this.getAccountInfo();
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          if (response.status == 200) {
            this.SuccessDialog = true;
            this.dialog = false;
            this.validateDialogeMessage = "Shop installed successfully!";
          }
        })
        .catch((e) => {
          this.assignShopError = "error";

          this.showAssignShopError = true;
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          this.getAccountInfo();
          console.log("ValidateService service error:", e);
        });
    },
    connectShop() {
      const params = {
        storeUrl: this.shopifyURL,
        platformName: "shopifyfeeder",
      };
      RegisterationSetup.installLinkRetailer(params)
        .then((response) => {
          this.redirectURL = response.data.redirectUrl;
          window.location.replace(this.redirectURL);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
