import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  // Supplier
  getRegisterSetupInit() {
    return api.post("sellers/steps/init", {}, { headers: authHeader() });
  },
  submitCompanyInformationStep(data, step) {
    return api.post("sellers/steps/" + step, data, { headers: authHeader() });
  },
  submitFinancialInformationStep(data, step) {
    return api.post("sellers/steps/" + step, data, { headers: authHeader() });
  },
  submitIntegrationStep(data, step) {
    return api.post("sellers/steps/" + step, data, { headers: authHeader() });
  },

  // Retailer
  installLinkRetailer(data) {
    return api.post("retailers/integrations/install", data, {
      headers: authHeader(),
    });
  },
};
