import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  sellerValidate(data) {
    return api.post("sellers/validate", data, { headers: authHeader() });
  },
  retailerValidate(data) {
    return api.post("retailers/integrations/validate", data, {
      headers: authHeader(),
    });
  },
};
